import { defineStore } from "pinia";
import { Login, User } from "types";

export const useUserStore = defineStore("user", () => {
  const user = useCookie("USER_COOKIE", {
    maxAge: 60 * 60 * 24,
  });
  const token = useCookie("TOKEN_COOKIE", {
    maxAge: 60 * 60 * 24,
  });
  const refreshToken = useCookie("REFRESH_TOKEN_COOKIE", {
    maxAge: 60 * 60 * 24 * 3,
  });

  // const token = useCookie("TOKEN_COOKIE", {
  //   maxAge: 60,
  // });
  // const refreshToken = useCookie("REFRESH_TOKEN_COOKIE", {
  //   maxAge: 60 * 3,
  // });

  const transferDepositRequest = useCookie("TRANSFER_DEPOSIT_REQUEST_COOKIE", {
    maxAge: 60 * 10,
  });
  const transferDepositCashId = useCookie("TRANSFER_DEPOSIT_CASH_ID", {
    maxAge: 60 * 10,
  });
  const config = useRuntimeConfig();
  const base_api = config.public.apiUri;

  const setToken = (data?: string) => (token.value = data);
  const setUser = (data?: any) => (user.value = data);
  const setRefreshToken = (data?: string) => (refreshToken.value = data);
  const setCredit = (data?: string) => (refreshToken.value = data);
  const setTransferDepositRequest = (data?: string) =>
    (transferDepositRequest.value = data);
  const setTransferDepositCashId = (data?: string) =>
    (transferDepositCashId.value = data);
  const signIn = async (data: Login) => {
    const res = await $fetch(`${base_api}/user/login`, {
      method: "POST",
      body: data,
    })
      .then((resdata: any) => {
        setToken(resdata.token);
        setUser(resdata.user);
        setRefreshToken(resdata.refreshToken);
        return resdata;
      })
      .catch((err) => {
        setToken(null);
        setUser(null);
        setRefreshToken(null);
        return err.data;
      });
    return res;
  };

  const signInAdmin = async (data: Login) => {
    const res = await $fetch(`${base_api}/login`, {
      method: "POST",
      body: data,
    })
      .then((resdata: any) => {
        setToken(resdata.token);
        setUser(resdata.user);
        setRefreshToken(resdata.refreshToken);
        return resdata;
      })
      .catch((err) => {
        setToken(null);
        setUser(null);
        setRefreshToken(null);
        return err.data;
      });
    return res;
  };

  const signOut = () => {
    try {
      const res = $fetch<User>(`${base_api}/logout`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token.value}` },
      });
      user.value = null;
      token.value = null;
      refreshToken.value = null;
      setToken(null);
      setUser(null);
      setRefreshToken(null);
      refreshCookie("USER_COOKIE");
      refreshCookie("TOKEN_COOKIE");
      refreshCookie("REFRESH_TOKEN_COOKIE");
      return res;
    } catch (error) {
      user.value = null;
      token.value = null;
      refreshToken.value = null;
      setToken(null);
      setUser(null);
      setRefreshToken(null);
      refreshCookie("USER_COOKIE");
      refreshCookie("TOKEN_COOKIE");
      refreshCookie("REFRESH_TOKEN_COOKIE");
    }
  };

  const getCredit = async () => {
    try {
      const res = await $fetch<User>(`${base_api}/credit`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token.value}` },
      });

      setCredit(res);
    } catch (error) {
      setToken(null);
      setUser(null);
      setRefreshToken(null);
    }
  };

  const setDeposit = async (data) => {
    alert(data);
    setTransferDepositRequest(data);
  };

  const apiRefresh = async (token: Token) => {
    const res = await $fetch(`${base_api}/auth/refresh`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token.token}` },
    })
      .then((resdata: any) => {
        setToken(resdata.accesstoken);
        setRefreshToken(resdata.refreshToken);
        refreshCookie("USER_COOKIE");
        refreshCookie("TOKEN_COOKIE");
        refreshCookie("REFRESH_TOKEN_COOKIE");
        return resdata;
      })
      .catch((err) => {
        setToken(null);
        setRefreshToken(null);
        return err.data;
      });

    return res;
  };

  return {
    user,
    token,
    refreshToken,
    signOut,
    signInAdmin,
    signIn,
    setUser,
    setToken,
    setRefreshToken,
    transferDepositRequest,
    setTransferDepositRequest,
    transferDepositCashId,
    setTransferDepositCashId,
    apiRefresh,
  };
});
